import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "place_id"];

  connect() {
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    var lat = Number(this.data.get("latitude"));
    var long = Number(this.data.get("longitude"));
    var zoom = Number(this.data.get("zoom"));
    var placeId = this.data.get("placeId");

    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(lat, long),
      zoom: zoom
    });

    var mapType = this.data.get("maptype");

    if (mapType == "markers") {
      this.drawClusterer(this.map);
    } else {
      this.marker = new google.maps.Marker({
        map: this.map,
        position: { lat: lat, lng: long }
      });
    }
  }

  initialize() {}

  drawClusterer(map) {
    var locations = JSON.parse(this.data.get("markers"));

    var markers = locations.map(function(location, i) {
      var marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng }
      });
      var infoWindow = new google.maps.InfoWindow({
        content: location.infowindow
      });
      marker.addListener("click", function() {
        infoWindow.open(map, marker);
      });
      return marker;
    });

    var markerCluster = new MarkerClusterer(map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    });
  }
}