import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["checkbox", "discount"]
  static values = { discounturl: String, rancurl: String }

  initialize() {
  }

  convertToRanc() {
    var formData = this.getFormData()

    Rails.ajax({
      url: this.rancurlValue,
      type: 'POST',
      data: formData
    })
  }

  applyDiscount() {
    var formData = this.getFormData()

    Rails.ajax({
      url: this.discounturlValue,
      type: 'POST',
      data: formData
    })
  }

  submitDiscount() {
    this.discountTarget.value = ""
    this.selectedCheckedboxes().
      forEach((target) => this.discountTarget.value = target.value)
  }

  getFormData() {
    let formData = new FormData()

    this.selectedCheckedboxes().
      forEach((target) => formData.append("equipment_item_ids[]", target.value))

    return formData
  }

  getSelectedValues() {
    var selectedValues = []
    this.selectedCheckedboxes().
      forEach((target) => selectedValues.push(target.value))
    return selectedValues
  }

  selectedCheckedboxes() {
    return this.checkboxTargets.filter((target) => target.checked)
  }
}
